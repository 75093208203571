import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MultipleErrorMessageComponent } from "./multiple-error-message/multiple-error-message.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { MessageComponent } from "../home/message/message.component";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private snackBar: MatSnackBar) {}

  showMessage(messages: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: messages,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }

  showMultipleErrors(message: any, panelClass: string) {
    this.snackBar.openFromComponent(MultipleErrorMessageComponent, {
      data: message,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  showErrorMessage(msg: string, panelClass: string) {
    this.snackBar.openFromComponent(ErrorMessageComponent, {
      data: msg,
      panelClass: panelClass,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
    });
  }

  getMessages(error: any): any {
    let messages: string[] = [];
    
    if ( error?.error?.message?.length && Array.isArray(error.error.message) ) {
      messages = error.error.message.join(', ');
    } else {
      messages.push('Un error ha ocurrido');
    }

    return messages;
  }
}
