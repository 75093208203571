import type { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from '../core/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';


export const resetPasswordResolver: any = (route: any, _: any) => {
  const authService: AuthenticationService = inject(AuthenticationService);
  const verificationCode = route.queryParams["verificationCode"];
  console.log({verificationCode});
  

  if ( !verificationCode ) {
    return {
      error: true,
      message: 'No Verification Code',
      data: []
    };
  }

  authService.codeVerification(verificationCode).subscribe({
    next: (data: any) => {
      console.log({ data });
      
      return {
        error: false,
        data: verificationCode
      };
    },
    error: (error: HttpErrorResponse) => {
      console.log({error});
      
      return {
        error: true,
        message: 'Error Subscribing',
        data: error.error.errors
      }
    }
  });
};
